.contacts{
  background-color: #ffeacf;
  .container-map{
    width: 100%;
    height: 100%;
  }
  h2{
    margin: 60px 0 50px 0;
    width: 640px;
    margin-left: auto;
  }
  .container-contacts{
    margin-bottom: 60px;
    width: 640px;
    margin-left: auto;
    div{
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #171717;
      margin-bottom: 21px;
        span{
          font-weight: 700;
        }
        a{
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #171717;
        }
        .social{
          width: 30px;
          height: 30px;
          margin: 0 10px;
        }
    }
  }
}

@media screen and  (max-width: 1359px){
  .contacts{
    h2{
      max-width: 470px;
    }
    .container-contacts{
      max-width: 470px;
    }
  }
}
@media screen and  (max-width: 991px){
  .contacts{
    .container-map{

      height: 400px;
    }
    h2{
      margin-right: auto;
      max-width: 700px;
      margin-bottom: 30px;
      margin-top: 40px;
    }
    .container-contacts{
      max-width: 700px;
      margin-right: auto;
      margin-bottom: 40px;
      div{
        margin-bottom: 18px;
      }
    }
  }
}
@media screen and  (max-width: 767px){
  .contacts{
    .container-map{

      height: 300px;
    }
    h2{
      margin-right: auto;
      max-width: 520px;
      padding: 0 10px;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    .container-contacts{
      max-width: 520px;
      margin-right: auto;
      padding: 0 10px;
      margin-bottom: 30px;
      div{
        margin-bottom: 14px;
      }
    }
  }
}
@media screen and  (max-width: 575px){
  .contacts{
    h2{
      margin-right: auto;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 15px;
      margin-top: 25px;
    }
    .container-contacts{
      margin-bottom: 25px;
      br{
        display: none;
      }
      div{
        margin-bottom: 10px;
      }
      width: 100%;
      margin-right: auto;
      padding: 0 10px;
    }
  }
}

.breadcrumb{
  padding: 20px 0 0 0;
  ol{
    li{
      line-height: 1;
      position: relative;
      a{
        color: #1d1d1d;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        &:hover{
          text-decoration: underline;
        }
      }
      span{
        color: #737373;
        font-size: 14px;
        font-weight: 400;
      }
      &::after{
        content: "/";
        position: absolute;
        right: -2px;
        top:0px;
        color:#1d1d1d;
      }
      &:last-child{
        &::after{
          content: "";
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .breadcrumb{
    ol{
      li{
        a{
          font-size: 12px;
        }
        span{
          font-size: 12px;
        }
        &::after{
          font-size: 12px;
          top: 3px;
        }
      }
    }
  }
}


//Colors variable

$black: #1d1d1d;
$peach: #e9c88f;


ul,ol{
  margin: 0;
  padding: 0;
  list-style: none;
}
a{
  color:#fff;
}
a:hover{
  text-decoration: none;
}
a:active, a:focus { outline: none; }

input, textarea {outline:none;}
input:active, textarea:active {outline:none;}
:focus {outline:none;}
textarea {resize:none;}
textarea {resize:vertical;}
textarea {resize:horizontal;}
body{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
  position: relative;
  overflow-x: hidden;
  &:before{
    content: "";
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 990;
    background-color: rgba(36,26,18,.63);
  }
  .content{
    margin-bottom: auto;
    margin-top: 140px;
  }
  .page{
    padding: 50px 0 60px;
  }
  &.menu-active{
    overflow: hidden;
    &:before{
      display: block;
    }
  }
}
h2,h1{
  color: #171717;
  font-family: "Open Sans",sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  &.color-peach{
    color: #e9c88f;
  }
}
.btn-link{
  display: block;
  border: 1px solid #e9c88f;
  color: #e9c88f;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 30px;
  background-color: #1d1d1d;
  line-height: 1;
  transition: ease .4s;
  &:hover{
    background-color: #e9c88f;
    color: #1d1d1d;
    border-color: #1d1d1d;
  }
  &.border-black{
    border: 1px solid #1d1d1d;
    &:hover{
      background-color: #e9c88f;
      color: #1d1d1d;
      border-color: #1d1d1d;
    }
  }
  &.small{
    padding: 0 10px;
  }
}

.bg-black{
  background-color: #1d1d1d;
}

@media screen and  (max-width: 1359px){
  body{
    .content{
      margin-top: 137px;
    }
  }
}
@media screen and  (max-width: 991px){
  body{
    .content{
      margin-top: 80px;
    }
    .page{
      padding:40px 0 50px 0;
    }
  }
  h2,h1{
    font-size: 36px;
  }
}
@media screen and  (max-width: 767px){
  h2,h1{
    font-size: 32px;
  }
  body{
    .page{
      padding:30px 0 40px 0;
    }
  }
}
@media screen and  (max-width: 575px){
  body{
    .content{
      margin-top: 70px;
    }
    .page{
      padding: 30px 0;
    }
  }
  h2,h1{
    font-size: 28px;
  }
}
.fancybox-bg{
  background-color: rgba(36, 26, 18, 0.63);
}


//шапка
@import "repeat-blocks/header.scss";

//Подвал
@import "repeat-blocks/footer.scss";

//Контакты
@import "repeat-blocks/contacts.scss";

//Заказать звонок
@import "repeat-blocks/order-call.scss";

//Хлебные крошки
@import "repeat-blocks/breadcrumb.scss";


//ГЛАВНАЯ СТРАНИЦА

//Первый блок
@import "pages/main-page/main-block.scss";
//О салоне
@import "pages/main-page/about.scss";
//Услуги
@import "pages/main-page/services.scss";
//Акции
@import "pages/main-page/actions.scss";
//Наша команда
@import "pages/main-page/team.scss";


//Страница услуг

@import "pages/sevices-page/services-page.scss";

//Страница О салоне

@import "pages/about-page/about-page.scss";

//Страница Команда

@import "pages/team-page/team-page.scss";


//Страница Акции

@import "pages/actions-page/actions-page.scss";

//Страница Член команды

@import "pages/member-page/member-page.scss";

//Страница Контактов

@import "pages/contacts-page/contacts-page.scss";

//Страница Страница определенной услуги

@import "pages/detail-service-page/detail-service-page.scss";

//Страница Прайс

@import "pages/price-page/price-page.scss";


//Страница Вакансии

@import "pages/vacancy-page/vacancy-page.scss";


//Модальники

@import "repeat-blocks/modals.scss";


.btn-close{
  position: absolute;
  top:17px;
  right: 31px;
  z-index: 1006;
  .icon-close{

    width: 26px;
    height: 26px;
    position: relative;
    cursor: pointer;
    .close-block{
      height: 6px;
      width: 34px;
      background-color: $peach;
      position: absolute;
      transition: ease .4s;
      &:nth-child(1){
        left:-4px;
        top:10px;
        transform: rotate(45deg);
      }
      &:nth-child(2){
        right: -4px;
        top:10px;
        transform: rotate(-45deg);


      }
    }
    &:hover{
      .close-block{
        background-color: #867052;
      }
    }
  }
}
#modal-call{
  padding: 40px 50px;
  position: relative;
  width: 520px;
  background-color: $black;
  .head-form{
    color: $peach;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 16px;

  }
  .text-form{
    .big-text{
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      color: #fff;

    }
    .small-text{
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
  .container-input{
    position: relative;
    border: 1px solid $black;
    background-color: #2f2f2f;
    width: 100%;
    padding: 12px 20px;
    border-radius: 26px;
    margin-bottom: 20px;
    input{
      color: #2f2f2f;
      font-size: 16px;
      border: none;
      line-height: 1.2;
      padding: 0;
      width: 100%;
      z-index: 5;
      position: relative;
      background-color:transparent;
      &:focus{
        &+.placeholder{
          color: $black;
          top: 0px;
          transform: scale(.8);
        }
      }
    }
    .placeholder{
      position: absolute;
      color: #b6a99a;
      font-size: 16px;
      line-height: 1;
      white-space: nowrap;
      left: 20px;
      top: calc(50% - 8px);
      transition: ease .4s;
      z-index: 0;

    }
    &.name-user{
      input{
        &:focus{
          &+.placeholder{
            left: 12px;
          }
        }
      }
    }
    &.phone-user{
      input{
        &:focus{
          &+.placeholder{
            left: 2px;
          }
        }
      }
    }
    &.success{
      background-color: $peach;
      &.name-user{
        .placeholder{
          left: 12px;
          color: $black;
          top: 0px;
          transform: scale(.8);
        }
      }
      &.phone-user{
        .placeholder{
          left: 2px;
          top: 0px;
          color: $black;
          transform: scale(.8);
        }
      }
    }
    &.error{
      border-color:#de6036;
      .placeholder{
        color:#de6036;
      }
    }
    &.active{
      background-color: $peach ;
    }
  }
  input[type="submit"]{
    border:1px solid $peach;
    color: $peach;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    border-radius:30px;
    line-height: 1;
    padding: 16px 59.5px ;
    margin-bottom: 14px;
    transition: ease .4s;
    width: 100%;
    &:hover{
      background-color: $peach;
      color: $black
    }
  }

}
#modal-success{
  padding: 40px 50px;
  position: relative;
  width: 520px;
  background-color: $black;
  .head-success{
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
    color: $peach;
    font-weight: 700;
  }
  .text-success{
    font-size: 18px;
    line-height: 1;
    margin-bottom: 10px;
    color: $peach;
  }
  button{
    height: 50px;
    border-radius: 30px;
    width: 100%;
  }
}
#modal-error{
  padding: 40px 50px;
  position: relative;
  width: 520px;
  background-color: $black;
  .head-error{
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
    color: $peach;
    font-weight: 700;
  }
  .text-error{
    font-size: 18px;
    line-height: 1;
    margin-bottom: 10px;
    color: $peach;
  }
  button{
    height: 50px;
    margin-top: 20px;
    border-radius: 30px;
    width: 100%;
    border: 1px solid $peach;
    background-color: transparent;
    color: $peach;
    transition: ease .4s;
    &:hover{
      color: $black;
      background-color: $peach;
    }
  }
}

@media screen and (max-width: 575px){
  .btn-close{
    right: 10px;
    top:10px;
  }
  #modal-call{
    width: 290px;
    padding: 40px 20px 30px;
    .head-form{
      font-size: 20px;
      margin-bottom: 10px;
    }
    .text-form{
      .big-text{
        font-size: 15px;
        line-height: 19px;
      }
      .small-text{
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    .container-input{
      margin-bottom: 10px;
    }
    input[type="submit"]{
      margin-bottom: 10px;
      width: 100%;
      padding: 17px;
    }
    .text-politics{
      margin-bottom: 10px;
    }
    .footer-form{
      font-size: 15px;
    }
    a{
      font-size: 24px;
    }
  }
}

.contacts-page{

  .contacts-img{
    width: 100%;
    height: 100%;
    img{
      width: inherit;
      height: inherit;
      object-fit: cover;
      object-position: center;
    }
  }
  h1{
    margin: 0px 0 50px 0;
  }
  .container-contacts{
    margin-bottom: 20px;
    div{
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #171717;
      margin-bottom: 21px;
      span{
        font-weight: 700;
      }
      a{
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #171717;
      }
      .social{
        width: 30px;
        height: 30px;
        margin: 0 10px;
      }
    }
  }
}
@media screen and (max-width: 1359px){
  .contacts-page{

  }
}
@media screen and (max-width: 991px){
  .contacts-page{
    h1{
      margin-bottom: 30px;
    }
    .container-contacts{
      div{
        margin-bottom: 18px;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .contacts-page{
    h1{
      margin-bottom: 20px;
    }
    .container-contacts{
      margin-bottom: 20px;
      div{
        margin-bottom: 14px;
      }
    }
  }
}
@media screen and (max-width: 575px){
  .contacts-page{
    h2{
      margin-bottom: 15px;
    }
    .container-contacts{
      br{
        display: none;
      }
      div{
        margin-bottom: 10px;
      }
    }
  }
}

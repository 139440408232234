.detail-service-page{
  .price{
    color: #1d1d1d;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 30px;
  }
  h1{
    margin-bottom: 20px;
    line-height: 1.2;
  }
  h2{
    margin-bottom: 50px;
  }
  .bold{
    line-height: 24px;
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  ul{
    margin-bottom: 20px;
    li{
      color: #1d1d1d;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

  }
  .btn-link{
    display: inline-block;
    padding: 15px 64px 19px;
    border-color: $black;
    &:hover{
      border-color: $peach;
    }
  }
  .service-img{
    width: 100%;
    height: 100%;
    img{
      width: inherit;
      height: inherit;
      object-fit: cover;
      object-position: center;
    }
  }
  .bold-2{
    color: #1d1d1d;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  .examples{
    .example{
      margin-bottom: 20px;
      width: 100%;
      height: calc(100% - 20px);
      img{
        width: inherit;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .btn-link.social{
    margin-top: 40px;
    padding: 9px 35px 9px;
    img{
      margin: 0 5px;
      width: 30px;
      height: 30px;
      &.light{
        display: inline-block;
      }
      &.dark{
        display: none;
      }
    }
    &:hover{
      img{
        &.light{
          display: none;
        }
        &.dark{
          display: inline-block;
        }
      }
    }
  }

  .container-examples-slider {
    position: relative;

    .slides {
      padding: 0 47px;
      .slide {
        .example {
          width: 100%;
          margin: 0 auto;
          img {
            width: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
    .control{
      .btn-examples-prev,.btn-examples-next{
        position: absolute;
        width: 15px;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
        top: calc(50% - 15px);
        .icon-arrow{
          position: relative;
          &::before{
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            border: 2px solid #e9dccb;
          }
        }

        &:hover{
          .icon-arrow{
            &::before{
              border-color: #a69989;
            }
          }
        }
      }
      .btn-examples-prev{
        left: 0;
        .icon-arrow{
          &::before{
            left: 4px;
            transform: rotate(-45deg);
            top: 3px;
          }
        }
      }
      .btn-examples-next{
        right: 0;
        .icon-arrow{
          &::before{
            right: 4px;
            transform: rotate(45deg);
            top: 3px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 991px){
  .detail-service-page{
    h2{
      margin-bottom: 40px;
    }
    .bold-2{
      font-size: 20px;
      margin-bottom: 20px;
    }
    .btn-link{
      margin-bottom: 20px;
      display: inline-block;
      padding: 15px 64px 19px;
      border-color: $black;
      &:hover{
        border-color: $peach;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .detail-service-page{
    h2{
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 575px){
  .detail-service-page{
    h2{
      margin-bottom: 20px;
    }
    .container-examples-slider {
      position: relative;

      .slides {
        padding: 0 20px;
      }
    }
  }
}

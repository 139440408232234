.services-page{
  h1{
    margin-bottom: 50px;
  }
  p{
    margin-bottom: 0;
    color: #171717;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    &:last-child{
      margin-bottom: 50px;
    }
  }
  .name-hall{
    color: #1d1d1d;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 50px;
  }
  .container-service{
    display: block;
    margin-bottom: 20px;
    .service-img{
      width: 100%;
      height: calc(100% - 100px);
      img{
        width: inherit;
        height: inherit;
        object-fit: cover;
        object-position: center;
      }
    }
    .service-info{
      height: 80px;
      padding: 20px;
      background-color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .service-name{
        color: #ffffff;
        font-size: 22px;
        font-weight: 400;
        text-transform: uppercase;
        margin-right: 10px;
        line-height: 1.2;
      }
      .btn-link{

          padding: 11px 31px 13px;
      }
    }
  }
}
@media screen and (max-width: 991px){
  .services-page{
    h1{
      margin-bottom: 40px;
    }
    p {
      &:last-child {
        margin-bottom: 40px;
      }
    }
    .name-hall{
      margin-bottom: 40px;
      font-size: 26px;
    }
    .container-service{
      .service-info{
        padding: 10px;
        .service-name{
          font-size: 16px;
        }
        .btn-link{
          padding: 9px 15px 11px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .services-page{
    h1{
      margin-bottom: 30px;
    }
    p {
      &:last-child {
        margin-bottom: 30px;
      }
    }
    .name-hall{
      margin-bottom: 30px;
      font-size: 22px;
    }
  }
}
@media screen and (max-width: 575px){
  .services-page{
    h1{
      margin-bottom: 20px;
    }
    p {
      &:last-child {
        margin-bottom: 20px;
      }
    }
    .name-hall{
      margin-bottom: 20px;
      font-size: 18px;
    }
    .container-service{
      .service-info{
        .service-name{
          font-size: 14px;
        }
      }
    }
  }
}

.member-page{
  .member-img{
    width: 100%;
    height: 370px;
    background-color: #e6d7c4;
    img{
      margin: 0 auto;
      width: 370px;
      height: 370px;
      display: block;
      object-fit: contain;
      object-position: center;
    }
  }
  .member-info{
    .member-name{
      color: #171717;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 25px;
    }
    .member-post{
      margin-bottom: 50px;
      color: #737373;
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
    }
    .member-desc{
      font-size: 14px;
      p{
        color: #171717;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 30px;
      }
      ul{
        padding-left: 20px;
        margin-bottom: 30px;
        li{
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1359px){
  .member-page{

  }
}
@media screen and (max-width: 991px){
  .member-page{
    .member-info{
      .member-name{
        margin-bottom: 15px;
      }
      .member-post{
        margin-bottom: 30px;
      }
      .member-desc{
        p{
          color: #171717;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .member-page{

  }
}
@media screen and (max-width: 575px){
  .member-page{
    .member-img{
      width: 100%;
      height: 100%;
      background-color: #e6d7c4;
      img{
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
        object-position: center;
      }
    }
    .member-info{
      .member-name{
        margin-bottom: 10px;
        font-size: 22px;
      }
      .member-post{
        margin-bottom: 10px;
        font-size: 16px;
      }
      .member-desc{
        p{
          margin-bottom: 10px;
        }
      }
    }
  }
}

.main-block{
    padding: 10px 0 60px;
    .container-main-slider{
        position: relative;
        .slides{
            .slide{
                .main-action{
                    img{
                        width: 100%;
                    }
                }
            }
        }
        .control{
            .btn-main-prev,.btn-main-next{
                position: absolute;
                width: 15px;
                height: 30px;
                overflow: hidden;
                cursor: pointer;
                top: calc(50% - 15px);
                .icon-arrow{
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        border: 2px solid #e9dccb;
                    }
                }

                &:hover{
                    .icon-arrow{
                        &::before{
                            border-color: #a69989;
                        }
                    }
                }
            }
            .btn-main-prev{
                left: 20px;
                .icon-arrow{
                    &::before{
                        left: 4px;
                        transform: rotate(-45deg);
                        top: 3px;
                    }
                }
            }
            .btn-main-next{
                right: 20px;
                .icon-arrow{
                    &::before{
                        right: 4px;
                        transform: rotate(45deg);
                        top: 3px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px){
    .main-block {
        padding-bottom: 50px;
    }
}
@media screen and (max-width: 767px){
    .main-block {
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 575px){
    .main-block{
        padding-bottom: 30px;
        .container-main-slider{
            .control{
                .btn-main-prev{
                    left: 5px;
                }
                .btn-main-next{
                    right: 5px;

                }
            }
        }
    }
}

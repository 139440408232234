.vacancy-page{
  h1{
    margin-bottom: 20px;
  }
  p{
    color: #171717;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 50px;
  }
  .vacancy-list{
   .container-vacancy{
     margin-bottom: 60px;
     .vacancy-name{
       color: #171717;
       font-size: 24px;
       font-weight: 400;
       margin-bottom: 20px;
     }
     .vacancy-exp, .vacancy-desc{
       color: #171717;
       font-size: 14px;
       font-weight: 400;
       line-height: 24px;
     }
   }
  }
}
@media screen and (max-width: 991px){
  .vacancy-page{
    p{
      margin-bottom: 40px;
    }
    .vacancy-list{
      .container-vacancy{
        margin-bottom: 50px;

      }
    }
  }
}
@media screen and (max-width: 767px){
  .vacancy-page{
    p{
      margin-bottom: 30px;
    }
    .vacancy-list{
      .container-vacancy{
        margin-bottom: 30px;
        .vacancy-name{
          margin-bottom: 10px;
        }

      }
    }
  }
}
@media screen and (max-width: 767px){
  .vacancy-page{
    p{
      margin-bottom: 20px;
    }
    .vacancy-list{
      .container-vacancy{
        margin-bottom: 20px;
        .vacancy-name{
          margin-bottom: 5px;
        }

      }
    }
  }
}

.services{
  padding: 50px 0 60px;
  h2{
    margin-bottom: 50px;
  }
  .btn-link{
    padding: 16px 20px 18px;
  }
  .container-services{
    display: flex;
    flex-wrap: wrap;
    .service{
      width: 33.3333333%;
      position: relative;
      display: block;
      &::before{
        z-index: 10;
        position: absolute;
        content: "";
        display: block;
        top:0;
        left: 0;
        height: 4px;
        width: 0;
        transition: ease-out .6s;
        background-color: $peach;

      }
      &::after{
        content: "";
        z-index: 10;
        position: absolute;
        display: block;
        bottom: 0;
        right: 0;
        height: 4px;
        width: 0px;
        transition: ease-out .6s;
        background-color: $peach;

      }
      .background-dark{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 2;
        transition: ease .4s;
        &::before{
          z-index: 20;
          position: absolute;
          content: "";
          display: block;
          top:0;
          right: 0;
          height: 0px;
          width: 4px;
          transition: ease-out .6s;
          background-color: $peach;


        }
        &::after{
          z-index: 20;
          position: absolute;
          content: "";
          display: block;
          bottom:0;
          left: 0;
          height: 0px;
          width: 4px;
          transition: ease-out .6s;
          background-color: $peach;


        }
      }
      .btn-link{
        z-index: 3;
        position: absolute;
        padding: 11px 15px 13px;
        background-color: transparent;
        top: calc(40% - 20px);
        left: calc(50% - 80px);
        transition: ease .4s;
        color: transparent;
        border-color: transparent;
        &:hover{
          background-color: $peach;
          color: $black;
        }
      }
      .service-img{
        width: 100%;
        height: calc(100% - 80px);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .service-name{
        padding: 0 30px;
        display: flex;
        align-items: center;
        color: #171717;
        font-size: 22px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.2;
        height: 80px;
        position: relative;
        z-index: 2;
      }
      &:hover{
        &::before{
          width: 100%;
          left:unset;
          right: 0;
          opacity: 1;
        }
        &::after{
          width: 100%;
          right:unset;
          left: 0;
          opacity: 1;
        }
        .background-dark{
          background-color: rgba(26, 26, 26, 0.7);
          &::before{
            height: 100%;
            top:unset;
            bottom: 0;
            opacity: 1;
          }
          &::after{
            height: 100%;
            bottom:unset;
            top: 0;
            opacity: 1;
          }
        }
        .btn-link{
          color: $peach;
          border-color: $peach;
          &:hover{
            color: $black;
          }
        }
      }
      &:nth-child(odd){
        &::before,&::after{
          background-color: #fff;
        }
        .background-dark{
          &::before,&::after{
            background-color: #fff;
          }
        }
        .service-name{
          background-color: #fff;
        }
      }
      &:nth-child(even){
        &::before,&::after{
          background-color: #ffeacf;
        }
        .background-dark{
          &::before,&::after{
            background-color: #ffeacf;
          }
        }
        .service-name{
          background-color: #ffeacf;
        }
      }
    }
  }
}
@media screen and (max-width: 1359px){
  .services{
    .container-services{
      .service{
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 991px){
  .services{
    padding: 40px 0 50px;
    h2{
      margin-bottom: 40px;
    }
  }

}
@media screen and (max-width: 767px){
  .services{
    padding: 30px 0 40px;
    h2{
      margin-bottom: 30px;
    }
    .container-services{
      .service{
        .service-name{
          font-size: 18px;
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .services{
    h2{
      margin-bottom: 20px;
    }
    padding: 20px 0 30px;
    .container-services{
      margin-bottom: 30px;
      .service{
        width: 100%;
        .service-name{
          font-size: 18px;
          padding: 0 15px;
        }
      }
    }
  }
}

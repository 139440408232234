.about-page{
  h1,h2{
    margin-bottom: 50px;
  }
  p{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #1d1d1d;
    margin-bottom: 30px;
    &.italic{
      font-size: 18px;
      font-style: italic;
      line-height: 30px;
    }
  }
  .margin{
    margin-bottom: 40px;
  }
  .container-img{
    width: 100%;
    height: 100%;
    img{
      width: inherit;
      height: inherit;
      object-fit: contain;
      object-position: center;
    }
  }
  .container-brands-slider{
    position: relative;
    .slides{
      padding: 0 47px;
      .slide{

        .brand{
          margin: 0 auto;
          width: 245px;
          height: 60px;
          img{
            width: inherit;
            height: inherit;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
    .control{
      .btn-brands-prev,.btn-brands-next{
        position: absolute;
        width: 15px;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
        top: calc(50% - 15px);
        .icon-arrow{
          position: relative;
          &::before{
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            border: 2px solid #e9dccb;
          }
        }

        &:hover{
          .icon-arrow{
            &::before{
              border-color: #a69989;
            }
          }
        }
      }
      .btn-brands-prev{
        left: 0;
        .icon-arrow{
          &::before{
            left: 4px;
            transform: rotate(-45deg);
            top: 3px;
          }
        }
      }
      .btn-brands-next{
        right: 0;
        .icon-arrow{
          &::before{
            right: 4px;
            transform: rotate(45deg);
            top: 3px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .about-page{
    h1,h2{
      margin-bottom: 40px;
    }
    p{
      font-size: 12px;
      line-height: 20px;
      color: #1d1d1d;
      margin-bottom: 20px;
      &.italic{
        font-size: 16px;
        font-style: italic;
        line-height: 26px;
      }
    }
    .margin{
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 767px){
  .about-page{
    h1,h2{
      margin-bottom: 30px;
    }
    p{
      font-size: 12px;
      line-height: 20px;
      color: #1d1d1d;
      margin-bottom: 20px;
      &.italic{
        font-size: 14px;
        font-style: italic;
        line-height: 26px;
      }
    }
    .margin{
      margin-bottom: 20px;
    }
    .container-brands-slider{
      .slides{
        .slide{
          .brand{
            width: 200px;
            height: 50px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .about-page{
    h1,h2{
      margin-bottom: 20px;
    }
    .container-brands-slider{
      .slides{
        padding: 0 30px;
        .slide{
          .brand{
            width: 200px;
            height: 50px;
          }
        }
      }
    }
  }
}

.order-call{
  padding: 56px 0 46px;
  h2{
    margin-bottom: 24px;
  }
  p{
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 27px;
  }
  .container-form{
    form{
      margin: 0 -10px;
      display: flex;
      .container-input{
        flex-grow: 8;
        margin: 0 10px 20px 10px;
        position: relative;
        border: 1px solid #2f2f2f;
        background-color: #2f2f2f;
        padding: 12px 20px;
        transition: ease .4s;
        border-radius: 30px;
        input{
          border: none;
          background-color: transparent;
          line-height: 1;
          padding: 0;
          font-size: 16px;
          font-weight: 400;
          color: $black;
          width: 100%;
          position: relative;
          z-index: 5;
        }
        .placeholder{
          z-index: 0;
          white-space: nowrap;
          line-height: 1;
          color: #b6a99a;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          left: 20px;
          top: calc(50% - 7px);
          display: inline-block;
          transition: ease .4s;
        }
        &.active,&.success{
          background-color: $peach;
          border-color: $peach;
          .placeholder{
            color: $black;
            top: 4px;
            transform: scale(.8);
            &.name{
              left: 13px;
            }
            &.phone{
              left: 16px;
            }
          }
        }
        &.error{
          border-color: #de6036;
          .placeholder{
            color: #de6036;
          }
        }
      }
      input[type="submit"]{
        flex-grow: 7;
        border: 1px solid $peach;
        margin: 0 10px;
        border-radius: 30px;
        background-color: $black;
        font-size: 14px;
        font-weight: 400;
        color: $peach;
        transition: ease .4s;
        height: 50px;
        &:hover{
          color: $black;
          background-color: $peach;
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .order-call{
    padding: 46px 0 46px;
    .container-form{
      form{
        .container-input{
          width: 100%;
        }
        input[type="submit"]{
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .order-call{
    padding: 36px 0 36px;
    .container-form{
      form{
        flex-wrap: wrap;
        .container-input{
          width: calc(50% - 20px);
        }
        input[type="submit"]{
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .order-call{
    padding: 26px 0 26px;
    .container-form{
      form{
        flex-wrap: wrap;
        .container-input{
          width: 100%;
        }
      }
    }
  }
}

footer{
  padding: 62px 0 58px;
  .copyright{
    color: #fff;
    line-height: 1.1;
    font-size: 14px;
    font-weight: 400;
  }
  nav{
    ul{
      display: flex;
      justify-content: space-between;
      li{
        position: relative;
        line-height: 1;
        a{
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          transition: ease .4s;
          &:hover{
            color: $peach
          }
        }
        &::before{
          content: "";
          position: absolute;
          display: block;
          height: 1px;
          width: 0px;
          background-color: $peach;
          left: 0;
          top: -2px;
          transition: ease .4s;
          border-radius: 1px;
        }
        &::after{
          content: "";
          position: absolute;
          display: block;
          height: 1px;
          width: 0px;
          background-color: $peach;
          right: 0;
          bottom: -5px;
          transition: ease .4s;
          border-radius: 1px;
        }
        &:hover{
          &::before{
            width: 100%;
            right: 0;
            left: unset;
          }
          &::after{
            width: 100%;
            left: 0;
            right: unset;
          }
        }
      }
    }
  }

}
.menu-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top:0;
  right:-100vw ;
  transition: ease .4s;


  .menu-bg{

    nav{
      z-index: 1005;
      position: absolute;
      right: -240px;
      transition: right ease .4s;
      background-color: $black;
      width: 320px;
      padding: 20px 20px 20px 10px;
      height: 100vh;
      ul{
        padding-left: 10px;
        li{
          cursor: pointer;
          font-size: 16px;
          &:hover{
            &>.container-child{
              &>div{
                color:$peach;
              }
              &>.btn-arrow{
                .icon-block{
                  background-color: $peach;
                }
              }
            }
            &>a{
              color: $peach;
            }
            &>.hidden-list{
              li{
                a{
                  color: #fff;
                  font-size: 16px;
                }
                .container-child{
                  div{
                    color: #fff;
                    font-size: 16px;
                  }
                }
                &:hover{
                  &>a{
                    color: $peach;
                  }
                  &>.container-child{
                    &>div{
                      color: $peach;
                    }
                    &>.btn-arrow{
                      .icon-block{
                        background-color: $peach;
                      }
                    }
                  }
                }
              }
            }
          }
          &.main-item{
            margin-bottom: 10px;
            &>a, &>.container-child>div{
              font-size: 20px;
            }
          }
          a, .container-child div{
            color: #ffffff;
            transition: ease .4s;
            font-size: 20px;
          }
          &.parent{
            .container-child{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              transition: ease .4s;
              .btn-arrow{
                width: 20px;
                height: 12px;
                position: relative;
                flex-shrink: 0;
                margin-left: 10px;
                margin-top: 3px;
                .icon-block{
                  position: absolute;
                  border-radius: 4px;
                  width: 12px;
                  height: 4px;
                  background-color: #fff;
                  transition: ease .4s;
                  top:4px;
                  &:first-child{
                    left: 1px;
                    transform: rotate(45deg);
                  }
                  &:last-child{
                    right: 1px;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
            &.active{
              &>.container-child{
                  &>a{
                    color: $peach!important;
                  }
                  &>div{
                    color: $peach!important;
                  }
                  &>.btn-arrow{
                  transform: rotate(180deg);
                  .icon-block{
                    background-color: $peach;
                  }
                }
              }
              &>ul{
                height: auto;
                margin: 10px 0;
                li{
                  .container-child{
                    div{
                      font-size: 16px;
                    }
                  }
                  a{
                    font-size: 16px;
                  }
                }
              }
            }
            &>ul{
              height: 0;
              overflow: hidden;
              margin-bottom: 0;

            }
          }

        }

      }
      /*ul{
        li{
          margin-bottom: 3px;
          padding-left: 20px;
          position: relative;
          a{
            transition: ease .4s;
            font-size: 20px;
          }
          &.main-item{
            &::before{
              content: "";
              position: absolute;
              left:0px;
              top: 50%;
              bottom: 50%;
              width: 2px;
              background-color: $peach;
              transition: ease .4s;

            }
          }
          .container-child{
            display: flex;
            overflow: hidden;
            justify-content: flex-start;
            align-items: center;
            .btn-arrow{
              width: 20px;
              height: 20px;
              position: relative;
              flex-shrink: 0;
              margin-left: 10px;
              .icon-block{
                position: absolute;
                border-radius: 4px;
                width: 12px;
                height: 4px;
                background-color: #fff;
                transition: ease .4s;
                top:10px;
                &:first-child{
                  left: 1px;
                  transform: rotate(45deg);
                }
                &:last-child{
                  right: 1px;
                  transform: rotate(-45deg);
                }
              }

            }
            .hidden-list{

            }
          }
          &:hover{
            a{
              color: $peach;

            }
            &.main-item{
              &::before{
                top: 0;
                bottom: 0;
              }
            }
            .container-child{
              .btn-arrow{
                .icon-block{
                  background-color: $peach;
                }
              }
            }
          }
        }
      }*/
    }
  }
  &.active{
    right: 0;
    .menu-bg{
      nav{
        transition: ease .4s;
        right: 0px;
      }
    }
  }
}
@media screen and (max-width: 767px){
  footer{
    padding: 20px 0;
    nav{
      margin-bottom: 20px;
    }
    .copyright{
      text-align: center;
    }
  }
}
@media screen and (max-width: 575px){
  footer{
    nav{
      margin-bottom: 10px;
      ul{
        flex-wrap: wrap;
        li{
          display: inline;
          margin: 0 10px 10px 10px;
        }
      }
    }
  }
  .menu-container{
    .menu-bg{
      nav{
        width: 260px;
        ul{
          li{
            a{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.about{
  h2{
    margin: 50px 0;
  }
  p{
    color: #171717;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .btn-link{
    display: inline-block;
    padding: 11px 51px 13px;
    border-color: $black;
    margin-bottom: 60px;
    &:hover{
      border-color: $peach;
    }
  }
  .container-about{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 991px){
  .about{
    h2{
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .btn-link{
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 767px){
  .about{
    h2{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .btn-link{
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 575px){
  .about{
    h2{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .container-about{
      margin: 0 -10px;
      width: calc(100% + 20px);
    }
  }
}

.price-page{
  h1{
    margin-bottom: 50px;
  }
  .accordion{
    .accordion-head{
      background-color: #000;
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1;
      padding: 27px 40px 28px;
    }
    .accordion-body{
      border: 1px solid #dcdcdc;
      .block{
        border-bottom: 1px solid #dcdcdc;
        .block-head{

          padding: 18px 40px 17px ;
          color: #1d1d1d;
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          text-transform: uppercase;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .block-name{
          }
          .icon-arrow{
            width: 16px;
            height: 7px;
            position: relative;
            margin-top: 6px;
            margin-left: 10px;
            &::before{
              content: "";
              position: absolute;
              width: 16px;
              height: 7px;
              background: url("../../../images/icons/arrow.png") 100% no-repeat;
              background-size: contain;
              opacity: 1;
              transition: ease .4s;
            }
            &::after{
              content: "";
              position: absolute;
              width: 16px;
              height: 7px;
              background: url("../../../images/icons/arrow-active.png") 100% no-repeat;
              background-size: contain;
              opacity: 0;
              transition: ease .4s;
            }
          }

        }
        .block-body{
          overflow: hidden;
          height: 0px;
          transition: ease .4s;
          table{
            margin: 0px 40px 17px;
            width: calc(100% - 80px);
            tr{
              td.type{
                color: #1d1d1d;
                font-size: 14px;
                font-weight: 600;
                line-height: 36px;

              }
              td.name{
                color: #1d1d1d;
                font-size: 14px;
                font-weight: 400;
                padding-left: 26px;
                line-height: 36px;
              }
              td.price{
                text-align: right;
                line-height: 36px;
              }
            }
          }
        }
        &.active{
          .block-head{
            .icon-arrow{
              &::before{
                opacity: 0;
              }
              &::after{
                opacity: 1;
              }
            }
          }
          .block-body{
            height: auto;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1359px){
  .price-page{

  }
}
@media screen and (max-width: 991px){
  .price-page{
    h1{
      margin-bottom: 40px;
    }
  }
}
@media screen and (max-width: 767px){
  .price-page{
    h1{
      margin-bottom: 30px;
    }
    .accordion{
      .accordion-head{
        font-size: 12px;
        padding: 27px 10px 28px;
      }
      .accordion-body{
        border: 1px solid #dcdcdc;
        .block{
          border-bottom: 1px solid #dcdcdc;
          .block-head{
            padding: 18px 10px 17px ;
            font-size: 12px;
            .block-name{
              max-width: 85%;
            }
            .icon-arrow{
              width: 16px;
              height: 7px;
              position: relative;
              margin-top: 6px;
              &::before{
                content: "";
                position: absolute;
                width: 16px;
                height: 7px;
                background: url("../../../images/icons/arrow.png") 100% no-repeat;
                background-size: contain;
                opacity: 1;
                transition: ease .4s;
              }
              &::after{
                content: "";
                position: absolute;
                width: 16px;
                height: 7px;
                background: url("../../../images/icons/arrow-active.png") 100% no-repeat;
                background-size: contain;
                opacity: 0;
                transition: ease .4s;
              }
            }

          }
          .block-body{
            overflow: hidden;
            height: 0px;
            transition: ease .4s;
            table{

              margin: 0px 10px 17px;
              width: calc(100% - 20px);
              tr{
                td.type{
                  font-size: 12px;
                  line-height: 1.1;
                  padding: 10px 0;

                }
                td.name{
                  padding: 10px 0 10px 20px;
                  color: #1d1d1d;
                  font-size: 12px;
                  font-weight: 400;
                  padding-left: 18px;
                  line-height: 1.1;
                }
                td.price{
                  width: 25%;
                  padding: 10px 0;
                  text-align: right;
                  font-size: 12px;
                  line-height: 1.1;
                }
              }
            }
          }
          &.active{
            .block-head{
              .icon-arrow{
                &::before{
                  opacity: 0;
                }
                &::after{
                  opacity: 1;
                }
              }
            }
            .block-body{
              height: auto;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .price-page{
    h1{
      margin-bottom: 20px;
    }
  }
}

.team{
  padding: 30px 0 60px;
  h2{
    margin-bottom: 50px;
  }
  .container-team-slider{
    position: relative;
    .slides{
      padding: 0 47px;
      .slide{
        .member{
          width: 370px;
          height: 490px;
          margin: 0 auto;
          .member-img{
            width: 100%;
            img{
              width: 100%;
              height: calc(100% - 66px);
              object-fit: contain;
              object-position: center;
            }
          }
          .member-info{
            .name{
              color: #171717;
              font-size: 22px;
              font-weight: 600;
              text-align: center;
              line-height: 1;
              display: block;
              margin: 10px 0 5px;
              transition: ease .4s;
              height: 44px;
              &:hover{
                color: $peach;
              }
            }
            .post{
              color: #737373;
              font-size: 14px;
              font-weight: 400;
              text-align: center;
              line-height: 1;
              height: 28px;
            }
          }
        }
      }
    }
    .control{
      .btn-team-prev,.btn-team-next{
        position: absolute;
        width: 15px;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
        top: calc(50% - 15px);
        .icon-arrow{
          position: relative;
          &::before{
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            border: 2px solid #e9dccb;
          }
        }

        &:hover{
          .icon-arrow{
            &::before{
              border-color: #a69989;
            }
          }
        }
      }
      .btn-team-prev{
        left: 0;
        .icon-arrow{
          &::before{
            left: 4px;
            transform: rotate(-45deg);
            top: 3px;
          }
        }
      }
      .btn-team-next{
        right: 0;
        .icon-arrow{
          &::before{
            right: 4px;
            transform: rotate(45deg);
            top: 3px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1359px){
  .team{
    padding: 30px 0 50px;
    .container-team-slider{
      position: relative;
      .slides{
        .slide{
          .member{
            width: 270px;
            height: 390px;
            .member-info{
              .name{
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px){
  .team{
    h2{
      margin-bottom: 40px;
    }
    padding: 30px 0 40px;
  }
}
@media screen and (max-width: 767px){
  .team{
    h2{
      margin-bottom: 30px;
    }
    padding: 30px 0 30px;
  }
}
@media screen and (max-width: 575px){
  .team{
    h2{
      margin-bottom: 20px;
    }
    padding: 30px 0 20px;
    .container-team-slider{
      position: relative;
      .slides{
        padding: 0 20px;
        .slide{
          .member{
            width: 240px;
            height: 330px;
          }
        }
      }
    }
  }
}

header{
  padding: 18px 0 29px;
  position: fixed;
  top:0;
  z-index: 100;
  width: 100%;
  color: #fff;
  .logo{
    display: block;
    height: 78px;
    width: 234px;
    margin-top: 12px;
    img{
      width: inherit;
      height:inherit;
    }
  }
  .navigation{
    margin-bottom: 40px;
  }
  nav{
    ul{
      display: flex;
      justify-content: flex-end;
      li{
        line-height: 1;
        font-size: 13px;
        margin: 0 29px;
        position: relative;
        a{
          color: #ffffff;
          line-height: 1;
          font-size: 13px;
          font-weight: 300;
          text-transform: uppercase;
          line-height: 1;
          transition: ease .4s;
          &:hover{
            text-decoration: none;
            color: $peach;

          }
        }
        &:last-child{
          margin-right: 0;
        }
        &:first-child{
          margin-left: 0;
        }
        &::before{
          content: "";
          position: absolute;
          display: block;
          height: 1px;
          width: 0px;
          background-color: $peach;
          left: 0;
          top: -3px;
          transition: ease .4s;
          border-radius: 1px;

        }
        &::after{
          content: "";
          position: absolute;
          display: block;
          height: 1px;
          width: 0px;
          background-color: $peach;
          right: 0;
          bottom: -5px;
          transition: ease .4s;
          border-radius: 1px;

        }
        &:hover{
          &::before{
            width: 100%;
            right: 0;
            left: unset;
          }
          &::after{
            width: 100%;
            left: 0;
            right: unset;
          }
        }
      }
    }
  }
  .schedule, .address{
    padding-left: 16px;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    line-height: 1.25;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 1px;
      top: 3px;
      bottom: 1px;
      left: 0;
      background-color: $peach;
    }
  }
  .phone{
    display: block;
    color: $peach;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 2px;
    line-height: 1.1;
  }
  .btn-link{
    padding: 11px 33px 13px;
  }
  .phone-mobile{
    cursor: pointer;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin:0 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    img{
      width: 74px;
      height: 74px;
    }
  }
  .container-burger{
    .icon-burger{
      display: block;
      .icon-block{
        background-color: $peach;
        width: 50px;
        height: 4px;
        border-radius: 3px;
        &:nth-child(2){
          margin: 14px 0;
        }
      }

    }
  }
}
@media screen and (max-width: 1359px){
  header{
    .navigation{
      margin-bottom: 36px;
    }
    .phone{
      vertical-align: center;
    }
  }
}
@media screen and (max-width: 991px){
  header{
    padding: 10px 0 10px;
    .logo{
      height: 60px;
      width: 180px;
      margin-top: 0px;
    }
  }
}
@media screen and (max-width: 575px){
  header{
    .logo{
      height: 50px;
      width: 150px;
    }
    .container-burger{
      .icon-burger{
        display: block;
        .icon-block{
          background-color: $peach;
          width: 40px;
          height: 4px;
          border-radius: 3px;
          &:nth-child(2){
            margin: 8px 0;
          }
        }

      }
    }
    .phone-mobile {
      width: 30px;
      height: 30px;
      margin: 0 5px;
      img {
        width: 54px;
        height: 54px;
      }
    }
  }
}

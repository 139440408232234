.actions{
  padding: 60px 0 30px;
  h2{
    margin-bottom: 60px;
  }
  .btn-link{
    padding: 16px 49px 18px;
    border-color: $black;
    &:hover{
      border-color: $peach;
    }
  }
  .container-actions-slider{
    position: relative;
    .slides{
      padding: 0 47px;
      .slide{
        .action{
          width: 575px;
          margin: 0 auto;
          .action-img {
            width: 100%;
            img {
              width: 100%;
              height: calc(100% - 66px);
              object-fit: contain;
              object-position: center;
            }
          }
          .action-name {
              color: #171717;
              font-size: 24px;
              font-weight: 400;
              line-height: 1.2;
              display: block;
              margin: 14px 0 10px;
              transition: ease .4s;
              padding-right: 140px;

              &:hover {
                color: $peach;
              }
            }
        }
      }
    }
    .control{
      .btn-actions-prev,.btn-actions-next{
        position: absolute;
        width: 15px;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
        top: calc(50% - 15px);
        .icon-arrow{
          position: relative;
          &::before{
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            border: 2px solid #e9dccb;
          }
        }

        &:hover{
          .icon-arrow{
            &::before{
              border-color: #a69989;
            }
          }
        }
      }
      .btn-actions-prev{
        left: 0;
        .icon-arrow{
          &::before{
            left: 4px;
            transform: rotate(-45deg);
            top: 3px;
          }
        }
      }
      .btn-actions-next{
        right: 0;
        .icon-arrow{
          &::before{
            right: 4px;
            transform: rotate(45deg);
            top: 3px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px){
  .actions{
    padding: 40px 0 30px;
    h2{
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 767px){
  .actions{
    padding: 30px 0 30px;
    h2{
      margin-bottom: 30px;
    }
    .container-actions-slider{
      .slides{
        .slide{
          .action{
            .action-name {
              font-size: 18px;
              padding-right: 100px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .actions{
    padding: 20px 0 30px;
    h2{
      margin-bottom: 20px;
    }
    .container-actions-slider{
      margin-bottom: 30px;
      .slides{
        padding: 0 30px;
        .slide{
          .action{
            width: 100%;
            margin: 0 auto;
            .action-img {
              width: 100%;
              img {
                width: 100%;
                height: calc(100% - 66px);
                object-fit: cover;
                object-position: center;
              }
            }
            .action-name {
              font-size: 16px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}

.actions-page{
  &.page{
    padding-bottom: 30px;
  }
  h1{
    margin-bottom: 50px;
  }
  .action{
    margin-bottom: 30px;
    .container-img{
      width: 100%;
      height: calc(100% - 30px);
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
    .action-info{
      padding-top: 5px;
      .action-name{
        color: #171717;
        font-size: 24px;
        line-height: 1.1;
        font-weight: 400;
        margin-bottom: 25px;
      }
      .action-date-begin{
        background-color: #ffeacf;
        color: #171717;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        display: inline-block;
        padding: 8px 10px;
        margin-bottom: 25px;
      }
      .action-desc{
        color: #171717;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      .action-date-end{
        color: #171717;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      .speciality{
        color: #171717;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        a{
          color: #171717;
          white-space: nowrap;
        }

      }
    }
  }
}

@media screen and (max-width: 1359px){
  .actions-page{
    .action{
      .container-img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 991px){
  .actions-page{
    margin-bottom: 40px;
    h1{
      margin-bottom: 40px;
    }
    .action{
      margin-bottom: 20px;
      .container-img{
        width: 100%;
        height: 100%;
      }
      .action-info{
        padding-top: 0px;
        .action-name{
          margin-bottom: 10px;
        }
        .action-date-begin{
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .actions-page{
    h1{
      margin-bottom: 30px;
    }
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 575px){
  .actions-page{
    h1{
      margin-bottom: 20px;
    }
    margin-bottom: 20px;
    .action{
      margin-bottom: 10px;
      .action-info{
        .action-name{
          font-size: 20px;
        }
      }
    }
  }
}
